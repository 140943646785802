import React, { useState, useEffect } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  flexRender,
} from "@tanstack/react-table";
import "./tasks-table-view.css";
import ServiceRequest from "../../utils/service-request";
import { serviceHost } from "../../common/const";
import MultipleMemberComponent from "../project-config/multiple-member";
import Pagination from "../../components/pagination";

const TasksTableView = ({ tasks, proId, pagination }) => {
  const [headers, setHeaders] = useState([]);
  const [data, setData] = useState(tasks);
  const [users, setUsers] = useState({});
  const [showAssignUserModal, setShowAssignUserModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [projectUsers, setProjectUsers] = useState([]);
  const [isAssignUsersModalOpen, setIsAssignUsersModalOpen] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState(tasks);
  const [filters, setFilters] = useState({});
  const [showAllFilters, setShowAllFilters] = useState(false);

  const toggleAllFilterInputs = () => {
    setShowAllFilters((prevState) => !prevState);
  };

  const handleUserChange = (e, userId) => {
    if (e.target.checked) {
      setSelectedUsers([userId]);
    }
  };

  const handleSaveAssignedUsers = () => {
    // Use selectedUsers to assign to the task
    handleAssignUsers(selectedUsers);
    closeAssignUsersModal();
    closeAssignUserModal();
  };

  const handleTaskSelection = (taskId) => {
    setSelectedTasks((prevSelectedTasks) =>
      prevSelectedTasks.includes(taskId)
        ? prevSelectedTasks.filter((id) => id !== taskId)
        : [...prevSelectedTasks, taskId]
    );
  };

  const handleAssignUserClick = (task) => {
    console.log("Assign User Clicked for task:", task);
    setSelectedTask(task);
    setShowAssignUserModal(true);
  };

  const closeAssignUserModal = () => {
    setShowAssignUserModal(false);
  };

  const openAssignUsersModal = () => {
    if (selectedTasks.length === 0) {
      alert("Please select at least one task to assign.");
      return;
    }
    setIsAssignUsersModalOpen(true);
  };

  const closeAssignUsersModal = () => {
    setIsAssignUsersModalOpen(false);
  };

  // Fetch saved config
  const fetchSavedConfig = async () => {
    try {
      const existingConfigResponse = await ServiceRequest(
        "get",
        "json",
        `${serviceHost}/projects/getConfig/${proId}`
      );

      let existingConfig = existingConfigResponse.data?.config || [];

      existingConfig = existingConfig.sort((a, b) => {
        return parseInt(a.excelOrder) - parseInt(b.excelOrder);
      });

      // Map existingConfig to headers for table
      const mappedHeaders = existingConfig.map((config) => ({
        title: config.label,
        accessorKey: config.key,
        isSystem: config.isSystem,
      }));

      // Update the headers state
      setHeaders(mappedHeaders.length > 0 ? mappedHeaders : defaultHeaders);
      // setHeaders([
      //   ...mappedHeaders,
      //   { title: "", accessorKey: "add", isSystem: true }
      // ]);
    } catch (error) {
      console.error("Error fetching saved config:", error);
      // Fallback to default headers if fetch fails
      setHeaders(defaultHeaders);
    }
  };

  const fetchUserData = async (userIds) => {
    try {
      const userPromises = userIds.map((userId) =>
        ServiceRequest("get", "json", `${serviceHost}/users/${userId}`)
      );
      const userResponses = await Promise.all(userPromises);
      console.log("userResponses", userResponses);
      const userMap = userResponses.reduce((acc, user) => {
        acc[user.data._id] = user.data.name;
        return acc;
      }, {});
      setUsers(userMap);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const fetchProjectUsers = async () => {
    try {
      const response = await ServiceRequest(
        "get",
        "json",
        `${serviceHost}/projects/${proId}`
      );
      console.log("Fetched project users:", response.data.users);
      setProjectUsers(response.data.users || []);
    } catch (error) {
      console.error("Error fetching project users:", error);
    }
  };

  useEffect(() => {
    fetchSavedConfig();
    const uniqueUserIds = [...new Set(tasks.map((task) => task.userId))];
    console.log("uniqueUserIds", uniqueUserIds);
    if (uniqueUserIds.length > 0) {
      fetchUserData(uniqueUserIds);
    }
    fetchProjectUsers();
  }, [proId, tasks]);

  // Define default headers if no config exists
  const defaultHeaders = [
    { title: "Task Title", accessorKey: "title" },
    { title: "Start Date", accessorKey: "startDate" },
    { title: "End Date", accessorKey: "endDate" },
  ];

  // Prepare data to include custom fields
  const prepareData = (tasks) => {
    return tasks.map((task) => {
      const customFields = task.customFieldValues || {};
      return {
        ...task,
        ...customFields,
        userName: users[task.userId] || "N/A",
      };
    });
  };

  useEffect(() => {
    const preparedData = prepareData(tasks);
    console.log("Prepared data:", preparedData);
    setData(preparedData);
    setFilteredData(preparedData);
    setFilters({});
  }, [tasks, users]);

  // Filter data based on filters state
  const handleFilterChange = (columnId, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [columnId]: value,
    }));
    console.log("Updated filters:", {
      ...filters,
      [columnId]: value,
    });

    if (columnId === "selectUsers") {
      console.log(`Filtering by selectUsers: ${value}`);
    }
  };

  useEffect(() => {
    let newFilteredData = [...data];

    Object.keys(filters).forEach((key) => {
      if (filters[key] && typeof filters[key] === "string") {
        newFilteredData = newFilteredData.filter((row) => {
          const userName = users[row.userId] || "N/A";

          console.log("Filtering by:", filters[key], "for user:", userName);

          // Check if the filter matches any field value or the userName
          const matches = String(row[key])
            .toLowerCase()
            .includes(filters[key].toLowerCase());

          const userMatches = userName
            .toLowerCase()
            .includes(filters[key].toLowerCase());

          return matches || userMatches;
        });
      }
    });

    // Set the filtered data back to `filteredData`
    setFilteredData(newFilteredData);
    console.log("Filtered data:", newFilteredData);
  }, [filters, data, users]);

  // Setup the table instance
  const tableInstance = useReactTable({
    data: filteredData,
    columns: headers.map((header) => ({
      accessorKey: header.accessorKey,
      header: header.title,
      cell: (info) => {
        const value = info.getValue();
        const row = info.row.original;

        // if (header.accessorKey === "selectUsers") {
        //   console.log("selectUsers value:", row.userId);
        //   console.log("header.accessorKey:", header.accessorKey);
        //   return row.userId || "N/A";
        // }
        if (header.accessorKey === "selectUsers") {
          const userName = users[row.userId] || "N/A";

          return userName;
        }

        // Render value based on isSystem
        if (header.isSystem === false) {
          return row.customFieldValues?.[header.accessorKey] ?? "N/A";
        }

        // Check if value is an object, date, or other special type
        if (typeof value === "object" && value !== null) {
          if (value instanceof Date) {
            return value.toLocaleDateString();
          }
          return JSON.stringify(value); // Display as JSON string for objects
        }

        return value ?? "N/A"; // Default if value is null or undefined
      },
    })),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    pageCount: pagination.totalPages,
    //pageCount: Math.ceil(filteredData.length / recordsPerPage),
  });

  // const handleRecordsPerPageChange = (e) => {
  //   const value = Number(e.target.value);
  //   if (value > 0) {
  //     setRecordsPerPage(value);
  //     tableInstance.setPageSize(value);
  //   }
  // };
  // const handleInputBlur = () => {
  //   if (recordsPerPage <= 0) {
  //     setRecordsPerPage(1);
  //     tableInstance.setPageSize(1);
  //   }
  // };

  const handleAssignUsers = async (assignedUsers) => {
    try {
      const promises = selectedTasks.map(async (taskId) => {
        const response = await ServiceRequest(
          "post",
          "json",
          `${serviceHost}/tasks/assign`,
          {
            taskId,
            assignedUsers,
          }
        );

        const updatedTask = response.data;
        console.log("Updated task after assignment:", updatedTask);

        // Update the local data state
        setData((prevData) =>
          prevData.map((task) =>
            task._id === taskId ? { ...task, userId: updatedTask.userId } : task
          )
        );
      });

      await Promise.all(promises);
      alert("Users assigned successfully to the selected tasks!");
    } catch (error) {
      console.error("Error assigning users:", error);
    }
  };

  return (
    <div>
      {/* <button
        onClick={openAssignUsersModal}
        style={{ background: "#79F6FC", marginLeft: "5px", color: "black" }}
        title={"Assign Multiple Tasks"}
      >
        <i className="fa fa-tasks" aria-hidden="true"></i>
      </button> */}
      <div className="table-container">
        {/* <button onClick={openAssignUsersModal} style={{ background: "green" }}>
        Assign task to Selected Users
      </button> */}
        <table className="tasks-table">
          <thead>
            {tableInstance.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="table-header">
                <th>
                  Select
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <button
                      title="Assign Multiple Tasks"
                      onClick={openAssignUsersModal}
                      style={{
                        background:
                          "linear-gradient(90deg, rgba(240, 191, 42, 1) 31%, rgba(244, 185, 6, 1) 100%)",
                        border: "none",
                        color: "green",
                        padding: "5px 10px",
                        cursor: "pointer",
                      }}
                    >
                      <i className="fa fa-tasks" aria-hidden="true"></i>
                    </button>
                    <button
                      title="Filter Tasks"
                      onClick={toggleAllFilterInputs}
                      style={{
                        background:
                          "linear-gradient(90deg, rgba(240, 191, 42, 1) 31%, rgba(244, 185, 6, 1) 100%)",
                        border: "none",
                        color: "green",
                        padding: "5px 10px",
                        cursor: "pointer",
                        marginLeft: "2px",
                      }}
                    >
                      <i className="fa fa-filter"></i>
                    </button>
                  </div>
                </th>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}

                    {/* filtering */}
                    {showAllFilters && (
                      <input
                        type="text"
                        placeholder="Filter..."
                        onChange={(e) =>
                          handleFilterChange(header.id, e.target.value)
                        }
                      />
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {tableInstance.getRowModel().rows.map((row, index) => (
            <tr
              key={row.id}
              className={index % 2 === 0 ? "table-row" : "table-row-alt"}
            >
              <td>
                <input
                  type="checkbox"
                  checked={selectedTasks.includes(row.original._id)}
                  onChange={() => handleTaskSelection(row.original._id)}
                />
              </td>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}

              <td>
                <button
                  title="Assign Users"
                  onClick={() => handleAssignUserClick(row.original)}
                  style={{
                    background: "white",
                    border: "none",
                    color: "green",
                    padding: "5px 10px",
                    cursor: "pointer",
                  }}
                >
                  <i className="fa fa-user-plus" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
          ))}
        </table>
        {/* <div className="pagination-controls">
          <span className="records-info">
            Records per page:
            <input
              type="number"
              min="1"
              value={recordsPerPage}
              onChange={handleRecordsPerPageChange}
              onBlur={handleInputBlur}
              style={{ width: "60px", marginLeft: "5px" }}
            />
          </span>
          <button
            className="pagination-btn"
            onClick={() => tableInstance.previousPage()}
            disabled={!tableInstance.getCanPreviousPage()}
          >
            Previous
          </button>
          <span className="page-info">
            Page {tableInstance.getState().pagination.pageIndex + 1} of{" "}
            {tableInstance.getPageCount()}
          </span>
          <button
            className="pagination-btn"
            onClick={() => tableInstance.nextPage()}
            disabled={!tableInstance.getCanNextPage()}
          >
            Next
          </button>
          <span className="total-records">Total Records: {data.length}</span>
        </div> */}

        <Pagination
          type={pagination.type}
          totalRecords={pagination.totalCount}
          pageLength={pagination.pageLength}
          currentPage={pagination.currentPage}
          totalPages={pagination.totalPages}
          onPageLengthChange={pagination.onPageLengthChange}
          onGotoPage={pagination.onPageChange}
        />

        {showAssignUserModal && (
          <div className="modal-overlay">
            <div className="assign-user-modal">
              <div>
                <MultipleMemberComponent
                  allowMultipleUsers={true}
                  selectUsers={selectedTask?.selectUsers || []}
                  onInputChange={(users) => handleAssignUsers(users)}
                  onUserKeyPress={() => {}}
                  dropdownHidden={false}
                  projectUsersDropdown={
                    <div>
                      <ul style={{ listStyle: "none" }}>
                        {projectUsers.map((user) => (
                          <li key={user._id}>
                            <label>
                              <input
                                type="radio"
                                value={user._id}
                                checked={selectedUsers.includes(user._id)}
                                onChange={(e) => handleUserChange(e, user._id)}
                              />
                              {user.name}
                            </label>
                          </li>
                        ))}
                      </ul>
                    </div>
                  }
                  assignusers={<div>Assign users here</div>}
                />
              </div>

              <div className="modal-actions">
                <button
                  style={{ background: "blue" }}
                  onClick={handleSaveAssignedUsers}
                >
                  Save
                </button>
                <button onClick={closeAssignUserModal}>Close</button>
              </div>
            </div>
          </div>
        )}

        {isAssignUsersModalOpen && (
          <div className="modal-overlay">
            <div className="assign-user-modal">
              <h3 style={{ color: "black" }}>Assign Tasks to User</h3>

              <div>
                <MultipleMemberComponent
                  allowMultipleUsers={true}
                  selectUsers={selectedTask?.selectUsers || []}
                  onInputChange={(users) => handleAssignUsers(users)}
                  onUserKeyPress={() => {}}
                  dropdownHidden={false}
                  projectUsersDropdown={
                    <div>
                      <ul style={{ listStyle: "none" }}>
                        {projectUsers.map((user) => (
                          <li key={user._id}>
                            <label>
                              <input
                                type="radio"
                                value={user._id}
                                checked={selectedUsers.includes(user._id)}
                                onChange={(e) => handleUserChange(e, user._id)}
                              />
                              {user.name}
                            </label>
                          </li>
                        ))}
                      </ul>
                    </div>
                  }
                  assignusers={<div>Assign users here</div>}
                />
              </div>

              <div className="modal-actions">
                <button
                  style={{ background: "blue" }}
                  onClick={handleSaveAssignedUsers}
                >
                  Save
                </button>
                <button onClick={closeAssignUsersModal}>Close</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TasksTableView;
