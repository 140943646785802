import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./user.css";
import * as validate from "../../common/validate-entitlements";
import { getAllCompanies } from "../../services/company/company-service";
import Auth from "../../utils/auth";

const UserList = React.memo((props) => {
  console.log("User data:", props.users);
  const [companies, setCompanies] = useState({});

  // // Get the logged-in user's role
  // const currentUserRole = Auth.get("userRole");
  // const currentUserId = Auth.get("userId");
  // let currentUserCompanyId;

  // // Find the user that matches the currentUserId
  // const currentUser = props.users.find((user) => user._id === currentUserId);
  // if (currentUser) {
  //   currentUserCompanyId = currentUser.companyId; // Store the found companyId
  // }

  // const UserList = (props) => {
  // console.log("userlist rendered");
  let users = props.users;

  useEffect(() => {
    const fetchCompanies = async () => {
      const { response, err } = await getAllCompanies();

      console.log("Fetched companies response:", response);
      if (response && Array.isArray(response.data)) {
        const companiesMap = response.data.reduce((acc, company) => {
          acc[company._id] = company.companyName;
          return acc;
        }, {});
        setCompanies(companiesMap);
      } else {
        console.error(
          "Error fetching companies:",
          err || "Response data is not an array"
        );
      }
    };

    fetchCompanies();
  }, []);

  //console.log("companies", companies);

  // const filteredUsers =
  //   currentUserRole === "admin"
  //     ? users
  //     : users.filter((user) => {
  //         console.log(
  //           `Checking user: ${user.name}, Company ID: ${user.companyId}`
  //         );
  //         return user.companyId == currentUserCompanyId;
  //       });

  // console.log("Filtered Users after checking:", filteredUsers);

  // const userList = filteredUsers.map((user) => {
  //   let editUser = validate.validateAppLevelEntitlements(
  //     props.appLevelAccess,
  //     "Users",
  //     "Edit"
  //   );
  //   let deleteUser = validate.validateAppLevelEntitlements(
  //     props.appLevelAccess,
  //     "Users",
  //     "Delete"
  //   );

  var userList = Object.keys(users).map((index) => {
    let user = users[index];

    let editUser = validate.validateAppLevelEntitlements(
      props.appLevelAccess,
      "Users",
      "Edit"
    );
    let deleteUser = validate.validateAppLevelEntitlements(
      props.appLevelAccess,
      "Users",
      "Delete"
    );

    return (
      <React.Fragment>
        <li
          className="list-group-item d-flex justify-content-between align-items-center"
          key={user._id}
        >
          <Link to={"/userPerformanceReports/" + user._id} className="links">
            <i className="fas fa-user" style={{ fontSize: "15px" }}></i> &nbsp;
            {user.name}{" "}
            <small>
              &nbsp;({user.role.charAt(0).toUpperCase() + user.role.slice(1)})
            </small>
            {/* <small>&nbsp;(Company ID: {user.companyId})</small> */}
            <small>&nbsp;({companies[user.companyId] || "Unknown"})</small>
          </Link>

          <span>
            {editUser ? (
              <span
                className="btn btn-xs btn-outline-info "
                title="Edit User"
                onClick={props.editUserWindow.bind(this, user._id, user)}
              >
                <i className="fas fa-pencil-alt"></i>
              </span>
            ) : (
              ""
            )}
            &nbsp;
            {deleteUser ? (
              <span
                className="btn btn-xs  btn-outline-danger"
                title="Delete User"
                onClick={props.onDeleteUser.bind(this, user._id)}
              >
                <i className="far fa-trash-alt"></i>
              </span>
            ) : (
              ""
            )}
          </span>
        </li>
      </React.Fragment>
    );
  });

  return (
    <div>
      <ul className="list-group list-group-flush">{userList}</ul>
    </div>
  );
});

export default UserList;
