import React, { useEffect, useState } from "react";
import * as reminderService from "../../services/reminder/reminder-service";
import EditReminderForm from "./edit-reminder-form";
import "./reminder-list.css";

const ReminderList = ({ projectId }) => {
  const [reminders, setReminders] = useState([]);
  const [isEdit, setIsEdit] = useState("");

  useEffect(() => {
    console.log("useEffect reminder list");
    console.log(projectId);

    // Fetch reminders for the given projectId
    reminderService
      .getAllReminders(projectId)
      .then(({ response, err }) => {
        if (err) {
          console.error("Error fetching reminders:", err);
          // Handle errors here, e.g., display an error message to the user
        } else {
          console.log("Fetched reminders:", response.data);

          // Check if response.data is an array or if reminders are nested differently
          if (Array.isArray(response.data)) {
            setReminders(response.data);
          } else if (response.data && Array.isArray(response.data.reminders)) {
            setReminders(response.data.reminders);
          } else {
            console.warn("Fetched reminders is not an array:", response.data);
            setReminders([]); // Set empty array if data is not as expected
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching reminders:", error);
        // Handle errors here, e.g., display an error message to the user
      });
  }, [projectId]);

  const handleEdit = (reminderId) => {
    setIsEdit(reminderId);
    console.log(`Edit reminder: ${reminderId}`);
  };

  const handleDelete = async (reminderId) => {
    if (window.confirm("Are you sure you want to delete this reminder?")) {
      try {
        const { response, err } = await reminderService.deleteReminder(
          reminderId
        );
        if (err) {
          console.error("Delete failed:", err);
          // Handle error response from the server
        } else if (response?.success) {
          setReminders(
            reminders.filter((reminder) => reminder._id !== reminderId)
          );
        } else {
          console.error("Delete failed:", response);
          // Handle delete failure here, e.g., display an error message
        }
      } catch (error) {
        console.error("Error deleting reminder:", error);
        // Handle errors here
      }
    }
  };

  return (
    <div className="reminder-table-container">
      <table className="reminder-table">
        <thead>
          <tr>
            <th>Reminder Time</th>
            <th>Frequency</th>
            <th>Notification Methods</th>
            <th>Actions</th>
          </tr>
        </thead>
        {/* <tbody> */}
        {Array.isArray(reminders) && reminders.length > 0 ? (
          reminders.map((reminder) => (
            <tr key={reminder._id}>
              <td>{reminder.reminderTime}</td>
              <td>{reminder.frequency}</td>
              <td>
                {reminder.notificationMethods.email && "Email "}
                {reminder.notificationMethods.inApp && "In-App "}
                {reminder.notificationMethods.sms && "SMS "}
              </td>
              <td>
                <button
                  className="edit-btn"
                  onClick={() => handleEdit(reminder._id)}
                >
                  <i className="fas fa-edit"></i>
                </button>
                <button
                  className="delete-btn"
                  onClick={() => handleDelete(reminder._id)}
                >
                  <i className="fas fa-trash-alt"></i>
                </button>
                {isEdit === reminder._id && (
                  <EditReminderForm
                    reminderId={reminder._id}
                    setIsEdit={setIsEdit}
                    setReminders={setReminders}
                  />
                )}
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="4">No reminders available.</td>
          </tr>
        )}
        {/* </tbody> */}
      </table>
    </div>
  );
};

export default ReminderList;
