import React from "react";

const ReplicateMultipleMemberComponent = ({
  allowMultipleUsers,
  handleInputChange,
  selectUsers,
  onUserKeyPress,
  projectUsersDropdown,
  dropdownHidden,
  assignusers,
}) => {
  const labelStyle = {
    fontSize: "small",
    marginRight: "7px",
    textTransform: "capitalize",
  };

  return (
    <div className="row">
      <div className="col-sm-6">
        <div className="form-group">
          {/* <label style={labelStyle}>Replicate for multiple Members</label> */}
          &nbsp;
          <input
            type="checkbox"
            name="allowMultipleUsers"
            onChange={handleInputChange}
            checked={allowMultipleUsers}
          />
        </div>
      </div>

      {allowMultipleUsers === true ? (
        <div className="col-sm-6">
          <div className="form-group">
            <label htmlFor="Select Users" style={labelStyle}>
              Select Members
            </label>
            <input
              type="text"
              value={selectUsers}
              className="form-control"
              onKeyDown={onUserKeyPress}
              onChange={handleInputChange}
              placeholder="Select Members"
              name="selectUsers"
              autoComplete="off"
              style={{ position: "relative" }}
            />
            <div
              style={{
                position: "absolute",
                left: "16px",
                top: "64px",
                width: "92%",
                border: "1px solid #ccc4c4",
                height: "100px",
                overflowY: "auto",
                background: "#fff",
                zIndex: 50,
              }}
              hidden={dropdownHidden}
            >
              <ul type="none" style={{ paddingLeft: "30px" }} id="search_users">
                {projectUsersDropdown}
              </ul>
            </div>
            {assignusers}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ReplicateMultipleMemberComponent;
