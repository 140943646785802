import React from "react";

export default class UploadTasksFile extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="upload-tasks-file">
        <div className="form-group">
          <input
            type="file"
            name="uploadFiles"
            multiple
            onChange={this.props.handleFileChange}
            className="form-control"
          />
        </div>
      </div>
    );
  }
}
