import React from "react";

class SelectStatusComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { status, onInputChange, project } = this.props;

    return (
      <div className="col-sm-12">
        <div className="form-group" style={{ marginTop: "0px" }}>
          {/* Dropdown for Selecting Status */}
          <select
            value={status}
            onChange={(e) => {
              console.log("Status changed to:", e.target.value);
              onInputChange(e);
            }}
            name="status"
            className="form-control"
          >
            <option value="" disabled>
              Select Status
            </option>

            {/* Dynamically map project categories */}
            {project && project.category ? (
              project.category.split(",").map((category, index) => (
                <option key={index} value={category.trim()}>
                  {category.trim()}
                </option>
              ))
            ) : (
              <option value="" disabled>
                No categories available
              </option>
            )}
          </select>
        </div>
      </div>
    );
  }
}

export default SelectStatusComponent;
