import React from "react";

class MultipleMemberComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      allowMultipleUsers,
      selectUsers,
      onInputChange,
      onUserKeyPress,
      dropdownHidden,
      projectUsersDropdown,
      assignusers,
    } = this.props;

    return (
      <div className="row">
        {/* Checkbox for Allowing Multiple Members */}
        <div
          className="col-sm-6"
          style={{
            //marginTop: "20px",
            marginLeft: "10px",
          }}
        >
          <div className="form-group">
            <input
              type="checkbox"
              name="allowMultipleUsers"
              onChange={onInputChange}
              checked={allowMultipleUsers}
            />
          </div>
        </div>

        {/* Select Members Input (Only show if Multiple Users are allowed) */}
        {allowMultipleUsers && (
          <div className="col-sm-6">
            <div className="form-group">
              <label>Select Members</label>
              <input
                type="text"
                value={selectUsers}
                className="form-control"
                onKeyDown={onUserKeyPress}
                onChange={onInputChange}
                placeholder="Select Members"
                name="selectUsers"
                autoComplete="off"
                style={{ position: "relative" }}
              />
              <div
                style={{
                  position: "absolute",
                  left: "16px",
                  top: "64px",
                  width: "92%",
                  border: "1px solid #ccc4c4",
                  height: "100px",
                  overflowY: "auto",
                  background: "#fff",
                  zIndex: 50,
                }}
                hidden={dropdownHidden}
              >
                <ul
                  type="none"
                  style={{ paddingLeft: "30px" }}
                  id="search_users"
                >
                  {projectUsersDropdown}
                </ul>
              </div>
              {assignusers}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default MultipleMemberComponent;
