import React, { Component } from "react";
import ServiceRequest from "../../utils/service-request";
import { serviceHost } from "../../common/const";
import "./custom-task-form.css";
import Auth from "../../utils/auth";

export default class ProjectCustomTaskForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: "",
      label: "",
      type: "",
      level: "",
      projectId: "",
      isMandatory: false,
      projects: [],
      errorMessage: "",
      currentUserCompanyId: null,
    };
  }

  async componentDidMount() {
    try {
      // Get the logged-in user's details (role, companyId)
      const currentUserId = Auth.get("userId");

      const response = await ServiceRequest(
        "get",
        "json",
        `${serviceHost}/users`
      );

      if (response && response.data) {
        // Find the logged-in user in the users list
        const currentUser = response.data.find(
          (user) => user._id === currentUserId
        );

        if (currentUser) {
          this.setState({ currentUserCompanyId: currentUser.companyId });

          // Filter projects based on the current user's companyId
          const filteredProjects = this.props.projects.filter(
            (project) => project.companyId === currentUser.companyId
          );

          // Update state with the filtered projects
          this.setState({ projects: filteredProjects });
        }
      }
    } catch (error) {
      console.error("Error fetching users or projects:", error);
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      key: this.state.key,
      label: this.state.label,
      projectId: this.state.projectId,
      type: this.state.type,
      level: this.state.level,
      isMandatory: this.state.isMandatory,
    };
    try {
      let response = await ServiceRequest(
        "post",
        "json",
        serviceHost + "/projects/addCustomTaskField",
        data
      );
      if (response) {
        this.props.setCustomTasksField((prev) => [...prev, response.data.data]);
        // Clear form fields after successful submission if needed
        this.setState({
          key: "",
          label: "",
          type: "",
          level: "",
          projectId: "",
          isMandatory: false,
          errorMessage: "",
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        this.setState({ errorMessage: "Duplicate key" });
      }
    }
  };

  handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    this.setState((prev) => {
      // return { ...prev, [name]: value };
      return { ...prev, [name]: type === "checkbox" ? checked : value };
    });
  };

  handleCancel = () => {
    this.setState({
      key: "",
      label: "",
      type: "",
      level: "",
      projectId: "",
      isMandatory: false,
      errorMessage: "",
    });
    if (this.props.closeForm) {
      this.props.closeForm();
    }
  };

  render() {
    console.log(
      "this.props.projects",
      this.props.projects,
      " this.state.projects",
      this.state.projects
    );
    return (
      <div id="form-container">
        <form onSubmit={this.handleSubmit}>
          <label htmlFor="project">Project</label>
          <select
            id="project"
            name="projectId"
            value={this.state.projectId}
            onChange={this.handleInputChange}
            required
          >
            <option value="">Select Project</option>
            {/* {this.props.projects.map((project) => (
              <option key={project._id} value={project._id}>
                {project.title}
              </option>
            ))} */}
            {this.state.projects.map((project) => (
              <option key={project._id} value={project._id}>
                {project.title}
              </option>
            ))}
          </select>

          <label htmlFor="level">Level</label>
          <select
            id="level"
            name="level"
            value={this.state.level}
            onChange={this.handleInputChange}
            required
          >
            <option value="">Select</option>
            <option value="project">Project</option>
            <option value="task">Task</option>
          </select>

          <label htmlFor="key">Key</label>
          <input
            id="key"
            name="key"
            type="text"
            placeholder="Key"
            value={this.state.key}
            onChange={this.handleInputChange}
            required
          />

          <label htmlFor="label">Label</label>
          <input
            id="label"
            name="label"
            type="text"
            placeholder="Label"
            value={this.state.label}
            onChange={this.handleInputChange}
            required
          />

          <label htmlFor="type">Type</label>
          <select
            id="type"
            name="type"
            value={this.state.type}
            onChange={this.handleInputChange}
            required
          >
            <option value="">Select</option>
            <option value="number">Number</option>
            <option value="text">Text</option>
          </select>

          <div className="inline-container">
            <label htmlFor="isMandatory">Is Mandatory</label>
            <input
              id="isMandatory"
              name="isMandatory"
              type="checkbox"
              checked={this.state.isMandatory}
              onChange={this.handleInputChange}
            />
          </div>

          <div className="button-container">
            <button type="submit" className="add-field-button">
              Add Field
            </button>
            <button
              type="button"
              onClick={this.handleCancel}
              className="cancel-button"
              style={{
                backgroundColor: "red",
              }}
            >
              Cancel
            </button>
          </div>
        </form>
        {this.state.errorMessage && <p>{this.state.errorMessage}</p>}
      </div>
    );
  }
}
