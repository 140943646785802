import React, { useState, useEffect } from "react";
import "./advanced-search-popup.css";
import SelectStatusComponent from "../project-config/select-status";

const AdvancedSearchPopup = ({
  fields,
  tasks,
  project,
  users,
  onApplyFilters,
  onClose,
}) => {
  const [filters, setFilters] = useState([{ field: "", value: "" }]);
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });

  console.log("projectUsers", project.projectUsers);

  const taskTypeOptions = [
    { label: "Task", value: "task" },
    { label: "Issue", value: "issue" },
  ];

  const priorityOptions = [
    { label: "High", value: "high" },
    { label: "Low", value: "low" },
    { label: "Medium", value: "medium" },
    { label: "Urgent", value: "urgent" },
  ];

  const handleFieldChange = (index, event) => {
    const newFilters = [...filters];
    newFilters[index].field = event.target.value;
    setFilters(newFilters);
  };

  const handleValueChange = (index, event) => {
    const newFilters = [...filters];
    newFilters[index].value = event.target.value;
    setFilters(newFilters);
  };

  const addFilter = () => {
    setFilters([...filters, { field: "", value: "" }]);
  };

  const parseDate = (dateString) => {
    const formats = [
      {
        regex: /^(\d{2})\/(\d{2})\/(\d{4})$/,
        parse: (match) => new Date(match[3], match[2] - 1, match[1]),
      }, // DD/MM/YYYY
      {
        regex: /^(\d{4})-(\d{2})-(\d{2})$/,
        parse: (match) => new Date(match[1], match[2] - 1, match[3]),
      }, // YYYY-MM-DD
      {
        regex: /^(\d{2})-(\d{2})-(\d{4})$/, // DD-MM-YYYY
        parse: (match) => new Date(match[3], match[2] - 1, match[1]),
      },
      {
        regex: /^(\d{4})\/(\d{2})\/(\d{2})$/, // YYYY/MM/DD
        parse: (match) => new Date(match[1], match[2] - 1, match[3]),
      },
    ];

    for (const { regex, parse } of formats) {
      const match = dateString.match(regex);
      if (match) {
        return parse(match);
      }
    }
    return null;
  };

  const dateFilters = () => {
    const { startDate, endDate } = dateRange;
    const start = parseDate(startDate);
    const end = parseDate(endDate);

    console.log("Start Date: ", start);
    console.log("End Date: ", end);

    if (!start && !end) {
      console.log("Both start and end dates are invalid.");
      return;
    }

    const dateFilteredTasks = tasks.filter((task) => {
      console.log("taskModifiedOn: ", task.modifiedOn);
      const modifiedOn = new Date(task.modifiedOn).getTime();
      const startTime = start ? start.getTime() : null;
      const endTime = end ? end.getTime() : null;

      return (
        (!startTime || modifiedOn >= startTime) &&
        (!endTime || modifiedOn <= endTime)
      );
    });

    console.log("Filtered Tasks: ", dateFilteredTasks);

    onApplyFilters(dateFilteredTasks);
    onClose();
  };

  const applyFilters = () => {
    const filteredTasks = tasks.filter((task) => {
      return filters.every((filter) => {
        let fieldKey = filter.field;
        const fieldValue = filter.value;

        // Map 'startDate' to 'modifiedOn'
        if (fieldKey === "startDate" || fieldKey === "endDate") {
          fieldKey = "modifiedOn";
        }

        let taskValue = task[fieldKey];

        if (!taskValue) {
          const user = users.find(
            (u) => u.name.toLowerCase() === fieldValue.toLowerCase()
          );
          if (user) {
            taskValue = user._id;
          } else {
            return false;
          }
        }

        console.log(
          "fieldKey",
          fieldKey,
          "fieldValue",
          fieldValue,
          "taskValue ",
          taskValue
        );

        if (fieldKey === "selectUsers") {
          console.log(
            "Field Key 'selectUsers' detected. Task Value: ",
            taskValue
          );

          return task.userId === taskValue;
        }

        if (!taskValue) return false;

        if (fieldKey === "status") {
          return taskValue.toLowerCase() === fieldValue.toLowerCase();
        }

        if (fieldKey === "modifiedOn") {
          const taskDate = new Date(task.modifiedOn);
          taskDate.setHours(0, 0, 0, 0); // Remove the time part for accurate comparison

          // Start date comparison
          if (filter.field === "startDate") {
            const startDate = new Date(fieldValue);
            startDate.setHours(0, 0, 0, 0);
            return taskDate.getTime() >= startDate.getTime();
          }

          // End date comparison
          if (filter.field === "endDate") {
            const endDate = new Date(fieldValue);
            endDate.setHours(23, 59, 59, 999); // Ensure the whole day is considered
            return taskDate.getTime() <= endDate.getTime();
          }
        }

        // if (fieldKey === "startDate") {
        //   const formattedFieldValue = parseDate(fieldValue);
        //   const formattedTaskValue = new Date(taskValue);

        //   if (formattedFieldValue && !isNaN(formattedTaskValue)) {
        //     return (
        //       formattedTaskValue.toDateString() ===
        //       formattedFieldValue.toDateString()
        //     );
        //   }
        //   return false;
        // }

        // if (fieldKey === "endDate") {
        //   const formattedFieldValue = parseDate(fieldValue);
        //   const formattedTaskValue = new Date(taskValue);

        //   if (formattedFieldValue && !isNaN(formattedTaskValue)) {
        //     return (
        //       formattedTaskValue.toDateString() ===
        //       formattedFieldValue.toDateString()
        //     );
        //   }
        //   return false;
        // }

        // Handle filtering for different data types
        if (typeof taskValue === "string") {
          return taskValue.toLowerCase().includes(fieldValue.toLowerCase());
        } else if (typeof taskValue === "number") {
          return taskValue === Number(fieldValue);
        } else if (taskValue instanceof Date) {
          return (
            new Date(taskValue).toDateString() ===
            new Date(fieldValue).toDateString()
          );
        }

        return false; // Return false if the data type doesn't match
      });
    });

    onApplyFilters(filteredTasks); // Pass filtered tasks to parent
    onClose();
  };

  useEffect(() => {
    console.log("fields structure", fields); // Check the fields structure
    console.log("tasks data", tasks); // Check the tasks data
  }, [fields, tasks]);

  return (
    <div className="popup">
      <div className="popup-content">
        <button id="cross-button" onClick={onClose}>
          <i className="fa fa-times"></i>
        </button>
        <h3>Advanced Search</h3>
        {filters.map((filter, index) => (
          <div key={index} className="filter-row">
            <select
              value={filter.field}
              onChange={(e) => handleFieldChange(index, e)}
            >
              <option value="">Select Field</option>
              {fields.map((field, i) => (
                <option key={i} value={field.key}>
                  {" "}
                  {/* field.key */}
                  {field.label}
                </option>
              ))}
            </select>
            {/* <input
              type="text"
              value={filter.value}
              onChange={(e) => handleValueChange(index, e)}
              placeholder="Enter value"
            /> */}

            {filter.field === "taskType" ? (
              <select
                value={filter.value}
                onChange={(e) => handleValueChange(index, e)}
              >
                <option value="">Select Task Type</option>
                {taskTypeOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            ) : filter.field === "status" && project && project.category ? (
              <select
                value={filter.value}
                onChange={(e) => handleValueChange(index, e)}
              >
                <option value="" disabled>
                  Select Status
                </option>
                {project.category.split(",").map((category, i) => (
                  <option key={i} value={category.trim()}>
                    {category.trim()}
                  </option>
                ))}
              </select>
            ) : filter.field === "priority" ? (
              <select
                value={filter.value}
                onChange={(e) => handleValueChange(index, e)}
              >
                <option value="">Select Priority</option>
                {priorityOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            ) : filter.field === "selectUsers" && project.projectUsers ? (
              <select
                value={filter.value}
                onChange={(e) => handleValueChange(index, e)}
              >
                <option value="" disabled>
                  Select Member
                </option>
                {project.projectUsers.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </select>
            ) : (
              <input
                type={
                  filter.field === "startDate" || filter.field === "endDate"
                    ? "date"
                    : "text"
                }
                value={filter.value}
                onChange={(e) => handleValueChange(index, e)}
                placeholder="Enter value"
              />
            )}
          </div>
        ))}

        <div className="button-container">
          <button onClick={addFilter}>Add Filter</button>
          <button onClick={applyFilters}>Apply Filters</button>
        </div>

        {/* Date Range Inputs */}
        {/* <div className="filter-row-inline">
          <label>From:</label>
          <input
            type="date"
            value={dateRange.startDate}
            onChange={(e) =>
              setDateRange({ ...dateRange, startDate: e.target.value })
            }
            placeholder="Start Date (DD-MM-YYYY)"
          />

          <label>To:</label>
          <input
            type="date"
            value={dateRange.endDate}
            onChange={(e) =>
              setDateRange({ ...dateRange, endDate: e.target.value })
            }
            placeholder="End Date (DD-MM-YYYY)"
          />
        </div>

        <button onClick={dateFilters}>Datewise Filters</button>
        <button onClick={onClose} style={{ background: "#dc3545" }}>
          Close
        </button> */}
      </div>
    </div>
  );
};

export default AdvancedSearchPopup;
