import React, { useState, useEffect } from "react";
import ServiceRequest from "../../utils/service-request";
import { serviceHost } from "../../common/const";
import UploadTasksFile from "./upload-tasks-file";
import SelectMemberComponent from "./select-member";
import SelectStatusComponent from "./select-status";
import TagInput from "./tag-input";
import TaskDependencyComponent from "./task-dependency";
import ReplicateMultipleMemberComponent from "./replicate-multiple-member";

const AddTaskForm = ({
  projectId,
  onTaskDynamicSubmit,
  handleFileChange,
  currentUserId,
  tags,
  tag,
  onKeyUp,
  allowMultipleUsers,
  onInputChange,
  selectUsers,
  handleUserKeyPress,
  dropdownHidden,
  projectUsersDropdown,
  assignusers,
  taskId,
  taskParamsId,
  userRole,
  editAll,
  currentUser,
  hiddenUserId,
  onShowAddUserModal,
  project,
  pTasks,
  hiddenDepId,
  description,
  title,
  startDate,
  endDate,
  status,
  storyPoint,
  taskType,
  priority,
  existingTaskData,
  assignUsers,
}) => {
  const [selectedFieldsData, setSelectedFieldsData] = useState([]);
  const [taskData, setTaskData] = useState();

  console.log("existingTaskData", existingTaskData);

  // useEffect(() => {
  //   const fetchFieldsData = async () => {
  //     try {
  //       const response = await ServiceRequest(
  //         "GET",
  //         "json",
  //         `${serviceHost}/projects/getConfig/${projectId}`
  //       );

  //       const data = response.data.config;

  //       if (Array.isArray(data)) {
  //         setSelectedFieldsData(data);

  //         if (taskId && existingTaskData) {
  //           // Initialize taskData with existingTaskData
  //           const initialTaskData = { ...existingTaskData };

  //           // Handle custom fields specifically
  //           const customFields = selectedFieldsData
  //             .filter((field) => !field.isSystem)
  //             .reduce((acc, field) => {
  //               acc[field.key] =
  //                 existingTaskData.customFieldValues[field.key] || "";
  //               return acc;
  //             }, {});

  //           // setTaskData({
  //           //   ...initialTaskData,
  //           //   ...customFields,
  //           // });
  //           const combinedTaskData = {
  //             ...initialTaskData,
  //             ...customFields,
  //           };

  //           console.log("Combined Task Data:", combinedTaskData);

  //           setTaskData(combinedTaskData);
  //         } else {
  //           const sortedFieldsData = [...data].sort((a, b) => {
  //             return parseInt(a.uiOrder) - parseInt(b.uiOrder);
  //           });

  //           setTaskData(
  //             sortedFieldsData.reduce((acc, field) => {
  //               acc[field.key] = field.defaultValue || "";
  //               return acc;
  //             }, {})
  //           );
  //         }
  //       } else {
  //         throw new Error("Response data.config is not an array");
  //       }
  //     } catch (err) {
  //       console.log(err.message);
  //     }
  //   };

  //   fetchFieldsData();
  // }, [projectId, taskId, existingTaskData]);

  useEffect(() => {
    const fetchFieldsData = async () => {
      try {
        const response = await ServiceRequest(
          "GET",
          "json",
          `${serviceHost}/projects/getConfig/${projectId}`
        );

        const data = response.data.config;

        if (Array.isArray(data)) {
          setSelectedFieldsData(data);

          // Set initial task data if there is an existing task
          if (taskId && existingTaskData) {
            setTaskData(existingTaskData);
          } else {
            const sortedFieldsData = [...data].sort((a, b) => {
              return parseInt(a.uiOrder) - parseInt(b.uiOrder);
            });

            setTaskData(
              sortedFieldsData.reduce((acc, field) => {
                acc[field.key] = field.defaultValue || "";
                return acc;
              }, {})
            );
          }
        } else {
          throw new Error("Response data.config is not an array");
        }
      } catch (err) {
        console.log(err.message);
      }
    };

    fetchFieldsData();
  }, [projectId, taskId]);

  useEffect(() => {
    if (taskId && existingTaskData && selectedFieldsData.length > 0) {
      const initialTaskData = { ...existingTaskData };

      const customFields = selectedFieldsData
        .filter((field) => !field.isSystem)
        .reduce((acc, field) => {
          acc[field.key] =
            existingTaskData.customFieldValues?.[field.key] || "";
          return acc;
        }, {});

      const combinedTaskData = {
        ...initialTaskData,
        ...customFields,
      };

      console.log("Combined Task Data:", combinedTaskData);
      setTaskData(combinedTaskData);
    }
  }, [existingTaskData, selectedFieldsData, taskId]);

  const handleInputChange = (key, value) => {
    setTaskData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const validateFields = () => {
    const missingFields = selectedFieldsData
      .filter((field) => field.isRequired && !taskData[field.key])
      .map((field) => field.label);

    if (missingFields.length > 0) {
      const message = `Please fill out the following required fields: ${missingFields.join(
        ", "
      )}`;
      alert(message);
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateFields()) {
      return;
    }

    console.log("taskData", taskData);

    const currentDate = new Date().toISOString().split("T")[0];
    const finalTaskData = {
      ...taskData,
      assignUsers: taskData.assignUsers || assignUsers,
      allowMultipleUsers: !!taskData.allowMultipleUsers,
      hiddenUserId: taskData.hiddenUserId || hiddenUserId,
      hiddenDepId: taskData.hiddenDepId || hiddenDepId,
      tag: taskData.tag || tag,
      isDeleted: taskData.isDeleted || false,
      completed: taskData.completed || false,
      startDate: taskData.startDate || currentDate,
      customFieldValues: selectedFieldsData
        .filter((field) => !field.isSystem)
        .reduce((acc, field) => {
          acc[field.key] = taskData[field.key] || "";
          return acc;
        }, {}),
    };

    console.log("finalTaskData taskData", finalTaskData);

    try {
      await onTaskDynamicSubmit(finalTaskData);
      alert("Task has been created successfully!");
    } catch (error) {
      console.error("Error creating task:", error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
          {selectedFieldsData
            .sort((a, b) => parseInt(a.uiOrder) - parseInt(b.uiOrder))
            .map((field, index) => {
              const isRequired = field.isRequired ? (
                <span style={{ color: "red" }}>*</span>
              ) : null;

              if (field.key === "startDate") {
                return (
                  <div
                    key={index}
                    className="form-group col-sm-6"
                    style={{ flex: "1 1 45%" }}
                  >
                    <label>
                      {field.label} {isRequired}
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={taskData[field.key] || startDate}
                      onChange={(e) =>
                        handleInputChange(field.key, e.target.value)
                      }
                    />
                  </div>
                );
              }

              if (field.key === "endDate") {
                return (
                  <div
                    key={index}
                    className="form-group col-sm-6"
                    style={{ flex: "1 1 45%" }}
                  >
                    <label>
                      {field.label} {isRequired}
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={taskData[field.key] || endDate}
                      onChange={(e) =>
                        handleInputChange(field.key, e.target.value)
                      }
                    />
                  </div>
                );
              }

              if (field.key === "title") {
                return (
                  <div
                    key={index}
                    className="form-group col-sm-6"
                    style={{ flex: "1 1 100%" }}
                  >
                    <label>
                      {field.label} {isRequired}
                    </label>
                    <input
                      className="form-control"
                      value={taskData[field.key] || title}
                      onChange={(e) =>
                        handleInputChange(field.key, e.target.value)
                      }
                    />
                  </div>
                );
              }

              // Render a textarea for the description field
              if (field.key === "description") {
                return (
                  <div
                    key={index}
                    className="form-group col-sm-6"
                    style={{ flex: "1 1 100%" }}
                  >
                    <label>
                      {field.label} {isRequired}
                    </label>
                    <textarea
                      //name="description"
                      className="form-control"
                      value={taskData[field.key] || description}
                      onChange={(e) =>
                        handleInputChange(field.key, e.target.value)
                      }
                      //onChange={onInputChange}
                      rows={3}
                    />
                  </div>
                );
              }

              if (field.key === "status") {
                return (
                  <div
                    key={index}
                    className="form-group col-sm-6"
                    style={{ flex: "1 1 45%" }}
                  >
                    <label>
                      {field.label} {isRequired}
                    </label>
                    <SelectStatusComponent
                      status={taskData[field.key] || status}
                      project={project}
                      onInputChange={onInputChange}
                    />
                  </div>
                );
              }

              if (field.key === "storyPoint") {
                return (
                  <div
                    key={index}
                    className="form-group col-sm-6"
                    style={{ flex: "1 1 45%" }}
                  >
                    <label>
                      {field.label} {isRequired}
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={taskData[field.key] || storyPoint}
                      onChange={(e) =>
                        handleInputChange(field.key, e.target.value)
                      }
                      min="1"
                    />
                  </div>
                );
              }

              if (field.key === "tag") {
                return (
                  <div
                    key={index}
                    className="form-group col-sm-6"
                    style={{ flex: "1 1 45%" }}
                  >
                    <label>
                      {field.label} {isRequired}
                    </label>
                    <TagInput tags={tags} onKeyUp={onKeyUp} />
                  </div>
                );
              }

              if (field.key === "taskType") {
                console.log("taskType from props:", taskType);
                console.log("taskData[field.key]:", taskData[field.key]);
                return (
                  <div
                    key={index}
                    className="form-group col-sm-6"
                    style={{ flex: "1 1 45%" }}
                  >
                    <label>
                      {field.label} {isRequired}
                    </label>
                    <select
                      className="form-control"
                      value={taskData[field.key] || taskType}
                      onChange={(e) =>
                        handleInputChange(field.key, e.target.value)
                      }
                    >
                      {field.options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                );
              }

              if (field.key === "priority") {
                return (
                  <div
                    key={index}
                    className="form-group col-sm-6"
                    style={{ flex: "1 1 45%" }}
                  >
                    <label>
                      {field.label} {isRequired}
                    </label>
                    <select
                      className="form-control"
                      value={taskData[field.key] || priority}
                      onChange={(e) =>
                        handleInputChange(field.key, e.target.value)
                      }
                    >
                      {field.options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                );
              }

              if (field.key === "uploadFiles") {
                return (
                  <div
                    key={index}
                    className="form-group col-sm-6"
                    style={{ flex: "1 1 45%" }}
                  >
                    <label>
                      {field.label} {isRequired}
                    </label>
                    <UploadTasksFile
                      handleFileChange={handleFileChange}
                      currentUserId={currentUserId}
                    />
                  </div>
                );
              }

              if (field.key === "depId") {
                return (
                  <div
                    key={index}
                    className="form-group col-sm-6"
                    style={{ flex: "1 1 45%" }}
                  >
                    <label>{field.label}</label>
                    <TaskDependencyComponent
                      onInputChange={onInputChange}
                      pTasks={pTasks}
                      hiddenDepId={hiddenDepId}
                    />
                  </div>
                );
              }

              if (field.key === "selectUsers") {
                return (
                  <div
                    key={index}
                    className="form-group col-sm-6"
                    style={{
                      flex: "1 1 45%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <label style={{ marginRight: "10px" }}>
                      {field.label} {isRequired}
                    </label>
                    <SelectMemberComponent
                      currentUser={currentUser}
                      hiddenUserId={hiddenUserId}
                      onShowAddUserModal={onShowAddUserModal}
                      project={project}
                      editAll={editAll}
                      taskId={taskId}
                      onInputChange={onInputChange}
                    />
                  </div>
                );
              }

              if (
                !taskId &&
                !taskParamsId &&
                (userRole !== "user" || editAll === true)
              ) {
                if (field.key === "allowMultipleUsers") {
                  return (
                    <div
                      key={index}
                      className="form-group col-sm-6"
                      style={{
                        flex: "1 1 45%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <label style={{ marginRight: "10px" }}>
                        {field.label} {isRequired}
                      </label>
                      <ReplicateMultipleMemberComponent
                        allowMultipleUsers={allowMultipleUsers}
                        handleInputChange={onInputChange}
                        selectUsers={selectUsers}
                        onUserKeyPress={handleUserKeyPress}
                        projectUsersDropdown={projectUsersDropdown}
                        dropdownHidden={dropdownHidden}
                        assignusers={assignusers}
                      />
                    </div>
                  );
                }
              } else {
                return null;
              }

              return (
                <div
                  key={index}
                  className="form-group col-sm-6"
                  style={{ flex: "1 1 45%" }}
                >
                  <label>
                    {field.label} {isRequired}
                  </label>
                  {field.componentType === "dropdown" ? (
                    <select
                      className="form-control"
                      value={taskData[field.key]}
                      onChange={(e) =>
                        handleInputChange(field.key, e.target.value)
                      }
                    >
                      {field.options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      type={field.dataType === "number" ? "number" : "text"}
                      className="form-control"
                      value={taskData[field.key]}
                      onChange={(e) =>
                        handleInputChange(field.key, e.target.value)
                      }
                    />
                  )}
                </div>
              );
            })}
        </div>

        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
    </div>
  );
};

export default AddTaskForm;
