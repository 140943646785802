import { serviceHost } from "../../common/const";
import ServiceRequest from "../../utils/service-request";

// Get all reminders for a project
export const getAllReminders = async (projectId) => {
  try {
    let response = await ServiceRequest(
      "get",
      "json",
      `${serviceHost}/reminders/reminders/${projectId}`
    );
    return { response, err: null };
  } catch (err) {
    return { response: null, err };
  }
};

// Add a new reminder
export const addReminder = async (reminder, projectId) => {
  try {
    let data = { reminder, projectId };
    let response = await ServiceRequest(
      "post",
      "json",
      `${serviceHost}/reminders/addReminder`,
      data
    );
    return { response, err: null };
  } catch (err) {
    return { response: null, err };
  }
};

// Edit an existing reminder
export const editReminder = async (reminder) => {
  try {
    let response = await ServiceRequest(
      "post",
      "json",
      `${serviceHost}/reminders/editReminder`,
      reminder
    );
    return { response, err: null };
  } catch (err) {
    return { response: null, err };
  }
};

// Delete a reminder
export const deleteReminder = async (reminderId) => {
  try {
    let response = await ServiceRequest(
      "post",
      "json",
      `${serviceHost}/reminders/deleteReminder`,
      [{ _id: reminderId }]
    );
    return { response, err: null };
  } catch (err) {
    return { response: null, err };
  }
};

// Get a reminder by ID
export const getReminderById = async (reminderId) => {
  try {
    let response = await ServiceRequest(
      "get",
      "json",
      `${serviceHost}/reminders/reminder/${reminderId}`
    );
    return { response, err: null };
  } catch (err) {
    return { response: null, err };
  }
};
