import React from "react";

class TaskDependencyComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { hiddenDepId, pTasks, onInputChange } = this.props;

    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group">
            {/* Input for selecting task dependency */}
            <input
              type="text"
              value={hiddenDepId}
              list="data"
              onChange={onInputChange}
              name="hiddenDepId"
              className="form-control"
              autoComplete="off"
              placeholder="Select Dependency Task"
            />

            {/* Datalist for project tasks */}
            <datalist id="data">
              {pTasks &&
                pTasks.map((t) => (
                  <option key={t.id} data-value={t.id}>
                    {t.title}
                  </option>
                ))}
            </datalist>
          </div>
        </div>
      </div>
    );
  }
}

export default TaskDependencyComponent;
