import React from "react";

class SelectMemberComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      currentUser,
      editAll,
      hiddenUserId,
      project,
      onShowAddUserModal,
      taskId,
      onInputChange,
    } = this.props;

    return (
      <div className="col-sm-6">
        <div
          className="form-group"
          style={{ display: "flex", alignItems: "center", marginTop: "20px" }}
        >
          {/* Conditional Add User Icon */}
          {currentUser && !editAll ? (
            ""
          ) : (
            <span
              title="Add users to project"
              onClick={() => onShowAddUserModal(taskId)}
              style={{
                marginRight: "10px",
                color: "#FF9800",
              }}
            >
              <i className="fas fa-plus-square"></i>
            </span>
          )}

          {/* Input for Selecting User */}
          {currentUser && !editAll ? (
            <input
              type="text"
              value={hiddenUserId}
              list="usersData"
              onChange={onInputChange}
              name="hiddenUserId"
              className="form-control"
              autoComplete="off"
              disabled
            />
          ) : (
            <input
              type="text"
              value={hiddenUserId}
              list="usersData"
              onChange={onInputChange}
              name="hiddenUserId"
              className="form-control"
              autoComplete="off"
              placeholder="Select User"
            />
          )}

          {/* Datalist for Project Users */}
          <datalist id="usersData">
            {project &&
              project.projectUsers.map((u) => (
                <option key={u.userId} data-value={u.userId}>
                  {u.name}
                </option>
              ))}
          </datalist>
        </div>
      </div>
    );
  }
}

export default SelectMemberComponent;
