import React, { useState, useEffect } from "react";
import * as reminderService from "../../services/reminder/reminder-service";
import "./edit-reminder-form.css";

const EditReminderForm = ({ reminderId, setIsEdit, setReminders }) => {
  const [reminder, setReminder] = useState(null);
  const [reminderTime, setReminderTime] = useState("");
  const [frequency, setFrequency] = useState("");
  const [notificationMethods, setNotificationMethods] = useState({
    email: false,
    inApp: false,
    sms: false,
  });

  useEffect(() => {
    const fetchReminder = async () => {
      try {
        const { response, err } = await reminderService.getReminderById(
          reminderId
        );
        if (err) {
          console.error("Error fetching reminder:", err);
        } else if (response?.data) {
          console.log("Fetched getReminderById data:", response.data);

          const { reminderTime, frequency, notificationMethods } =
            response.data;

          // Ensure notificationMethods is defined and has the expected structure
          setNotificationMethods({
            email: notificationMethods?.email || false,
            inApp: notificationMethods?.inApp || false,
            sms: notificationMethods?.sms || false,
          });

          setReminderTime(reminderTime || "");
          setFrequency(frequency || "");
          setReminder(response.data);
        } else {
          console.warn("Unexpected response data:", response);
        }
      } catch (error) {
        console.error("Error fetching reminder:", error);
      }
    };

    fetchReminder();
  }, [reminderId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { response, err } = await reminderService.editReminder({
        _id: reminderId,
        reminderTime,
        frequency,
        notificationMethods,
      });
      if (response?.status === 200) {
        setReminders((prevReminders) =>
          prevReminders.map((reminder) =>
            reminder._id === reminderId
              ? { ...reminder, reminderTime, frequency, notificationMethods }
              : reminder
          )
        );
        setIsEdit(""); // Close the edit form
      } else {
        console.error("Update failed:", err || response);
        // Handle update failure here
      }
    } catch (error) {
      console.error("Error updating reminder:", error);
      // Handle errors here
    }
  };

  if (!reminder) return null;

  return (
    <div className="edit-reminder-form">
      <form onSubmit={handleSubmit}>
        <div>
          <label>Reminder Time</label>
          <input
            type="time"
            value={reminderTime}
            onChange={(e) => setReminderTime(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Frequency</label>
          <select
            value={frequency}
            onChange={(e) => setFrequency(e.target.value)}
            required
          >
            <option value="one-time">One-Time</option>
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
          </select>
        </div>
        <div>
          <label>Notification Methods</label>
          <div>
            <input
              type="checkbox"
              checked={notificationMethods.email}
              onChange={(e) =>
                setNotificationMethods((prev) => ({
                  ...prev,
                  email: e.target.checked,
                }))
              }
            />
            <label>Email</label>
          </div>
          <div>
            <input
              type="checkbox"
              checked={notificationMethods.inApp}
              onChange={(e) =>
                setNotificationMethods((prev) => ({
                  ...prev,
                  inApp: e.target.checked,
                }))
              }
            />
            <label>In-App</label>
          </div>
          <div>
            <input
              type="checkbox"
              checked={notificationMethods.sms}
              onChange={(e) =>
                setNotificationMethods((prev) => ({
                  ...prev,
                  sms: e.target.checked,
                }))
              }
            />
            <label>SMS</label>
          </div>
        </div>
        <div className="button-group">
          <button type="submit">Save</button>
          <button type="button" onClick={() => setIsEdit("")}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditReminderForm;
