import { serviceHost } from "../../common/const";
import ServiceRequest from "../../utils/service-request";

export const getMonthlyTaskReport = async (
  reportParams,
  projectId,
  pagination
) => {
  try {
    let data = { reportParams, projectId, pagination };

    let response = await ServiceRequest(
      "post",
      "json",
      serviceHost + "/reports/getMonthlyTaskReport",
      data
    );
    return { response, err: null };
  } catch (err) {
    if (err) {
      return { response: null, err };
    }
  }
};
