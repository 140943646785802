import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReminderSettings from "../../components/reminder/reminder-settings";
import TaskMenu from "../tasks/task-menu";
import ReminderList from "../../components/reminder/reminder-list";

export default class Reminder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: true,
      projectName: props.context.state.projectName,
      appLevelAccess: props.context.state.appLevelAccess,
    };
  }

  componentDidMount() {
    if (this.state.appLevelAccess.length === 0) {
      this.props.context.actions.getAppLevelAccessRights();
    }
    this.setState({ isLoaded: false });
  }

  render() {
    return (
      <div className="container bg-white">
        {this.state.isLoaded ? (
          <div className="logo">
            <img src="/images/loading.svg" alt="loading" />
          </div>
        ) : (
          <React.Fragment>
            {this.props.projectId !== "000" ? (
              <span>
                <h3 className="project-title d-inline-block mt-3 mb-3">
                  <span title="back" className="d-inline-block">
                    <Link
                      to={`/project/tasks/${this.props.projectId}`}
                      className=""
                    >
                      <i className="fas fa-arrow-left "></i>
                    </Link>{" "}
                  </span>{" "}
                  {this.state.projectName}-Reminder
                </h3>{" "}
                <hr />
              </span>
            ) : (
              ""
            )}

            <div className="row">
              {this.props.projectId !== "000" ? (
                <TaskMenu
                  projectId={this.props.projectId}
                  context={this.props.context}
                />
              ) : (
                ""
              )}
            </div>

            <div className="row">
              <div className="col-sm-12 contentWrapper">
                <ReminderSettings
                  projectId={this.props.projectId}
                  context={this.props.context}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <ReminderList projectId={this.props.projectId} />
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}
