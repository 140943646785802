import React from "react";
import * as taskservice from "../../services/task/task-service";
import * as dateUtil from "../../utils/date-util";
import * as reminderService from "../../services/reminder/reminder-service";
import { serviceHost } from "../../common/const";
import "./reminder-settings.css";

export default class ReminderSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reminderEnabled: false,
      reminderTime: "",
      frequency: "",
      notificationMethods: {
        email: false,
        inApp: false,
        sms: false,
      },
      tasks: [], // To store the list of tasks
      // selectedTask: "", // To store the selected task
      message: "", // To handle errors or messages
      updatedTime: "", // To track the last update time
    };
  }

  componentDidMount() {
    console.log("Project ID:", this.props.projectId);
    this.loadProjectTasks(this.props.projectId);
  }

  loadProjectTasks = async (projectId) => {
    try {
      const { tasks, taskErr } = await taskservice.getProjectTasks(projectId);
      if (taskErr) {
        this.setState({
          message: taskErr,
          updatedTime: dateUtil.getTime(),
        });
      } else if (tasks && tasks.data.err) {
        this.setState({
          message: tasks.data.err,
          updatedTime: dateUtil.getTime(),
        });
      } else {
        this.setState({
          tasks: tasks.data.tasks,
          updatedTime: dateUtil.getTime(),
        });

        // Console log the tasks here
        console.log("Loaded tasks:", tasks.data.tasks);

        this.props.context.actions.updateState("projectName", tasks.data.title);
      }
    } catch (error) {
      this.setState({
        message: "An error occurred while fetching tasks.",
        updatedTime: dateUtil.getTime(),
      });
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      reminderEnabled: this.state.reminderEnabled,
      reminderTime: this.state.reminderTime,
      frequency: this.state.frequency,
      notificationMethods: this.state.notificationMethods,
      //selectedTask: this.state.selectedTask,
    };

    console.log("Submitting data:", data);

    try {
      const { response, err } = await reminderService.addReminder(
        data,
        this.props.projectId
      );
      alert("Settings saved successfully");
    } catch (error) {
      alert("Error saving settings aaaa");
    }
  };

  handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      this.setState((prevState) => ({
        notificationMethods: {
          ...prevState.notificationMethods,
          [name]: checked,
        },
      }));
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  handleReminderToggle = (e) => {
    this.setState({
      reminderEnabled: e.target.checked,
    });
  };

  render() {
    return (
      <div id="settings-container">
        <form onSubmit={this.handleSubmit}>
          <label>
            <input
              type="checkbox"
              name="reminderEnabled"
              checked={this.state.reminderEnabled}
              onChange={this.handleReminderToggle}
            />
            Enable Reminder
          </label>

          {this.state.reminderEnabled && (
            <>
              <label htmlFor="reminderTime">Reminder Time:</label>
              <input
                id="reminderTime"
                name="reminderTime"
                type="time"
                value={this.state.reminderTime}
                onChange={this.handleChange}
              />

              <label htmlFor="frequency">Frequency:</label>
              <select
                id="frequency"
                name="frequency"
                value={this.state.frequency}
                onChange={this.handleChange}
              >
                <option value="">Select</option>
                <option value="one-time">One-time</option>
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
              </select>

              <fieldset>
                <legend>Notification:</legend>
                <label>
                  <input
                    type="checkbox"
                    name="email"
                    checked={this.state.notificationMethods.email}
                    onChange={this.handleChange}
                  />
                  Email
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="inApp"
                    checked={this.state.notificationMethods.inApp}
                    onChange={this.handleChange}
                  />
                  In-App Notification
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="sms"
                    checked={this.state.notificationMethods.sms}
                    onChange={this.handleChange}
                  />
                  SMS
                </label>
              </fieldset>
            </>
          )}

          <button type="submit">Save</button>
        </form>
      </div>
    );
  }
}
