import React from "react";

export default class TagInput extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { tags, onKeyUp } = this.props;

    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group">
            <input
              type="text"
              name="tag"
              className="form-control"
              placeholder="Press comma to add Tag"
              onKeyUp={onKeyUp}
            />
            {tags && tags.length > 0 ? (
              <div
                style={{
                  height: "45px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                <i className="fas fa-tags mytags"></i> &nbsp; {tags}
              </div>
            ) : (
              <span>
                <i className="fas fa-tags mytags"></i> &nbsp;
                <small className="text-muted">Tags...</small>
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
}
